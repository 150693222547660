.calenderTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardsContainer {
  background: #e0e0e0;
  padding-top: 1px;
  padding-right: 1px;
  min-height: 60vh;
}

.buttonsContainer {
  display: inline-flex;
  flex-direction: row;
}

.monthCard {
  background: white;
  margin-left: 1px;
  margin-bottom: 1px;
}

.meetingCard {
  display: flex;
  justify-content: space-between;
}
