.Collapsible {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  height: min-content;
  padding: 0.5rem;
  position: relative;
  margin-top: 1rem;
}

.Collapsible__trigger .chevron {
  transition: transform 300ms;
}

.Collapsible__trigger.is-open .chevron {
  transform: rotateZ(180deg);
}
