@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

/* Functions and Directives */

.input-outline {
  @apply focus:outline-none focus:ring-2 focus:ring-safflowerOrange;
}

input {
  @apply input-outline;
}

textarea {
  @apply input-outline;
}

* {
  font-family: "Nunito Sans", sans-serif;
}
